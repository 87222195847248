.actionIcon {
    cursor: pointer;
}

.actionIcon:hover {
    color: #4c97d8;
}

.order_img {
    width: 70rem;
    height: 60rem;
}

.order_pdf {
    width: 70rem;
    height: 60rem;
}

.box_order_img {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.mobile_order_box {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.mobile_order_btn {
    height: 5rem;
    flex: 1;
    text-wrap: wrap;
}

@media screen and (max-width: 768px) {
    .order_img {
        max-width: 20rem;
        max-height: 40rem;
        margin: auto;
    }

    .box_order_img {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

}

.is-analysis {
    background-color: #ffdd004a;
}

.is-refused {
    background-color: #ff00004a
}